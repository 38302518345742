import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "pa-8 contract-container" },
    [
      _c(VIcon, { attrs: { "x-large": "", color: "primary" } }, [
        _vm._v("mdi-file-sign"),
      ]),
      _c("div", { domProps: { innerHTML: _vm._s(_vm.contract) } }),
      _vm.User_prop.type === _vm.UserTypeEnum.Investor
        ? _c(
            "span",
            {
              staticStyle: {
                display: "block",
                "font-weight": "bold",
                "text-align": "center",
              },
            },
            [_vm._v(_vm._s(_vm.$t("disclaimer_fee_renew")))]
          )
        : _vm._e(),
      _c(VRow, { staticClass: "ma-0 pa-7" }, [
        _c(
          "div",
          { staticClass: "container-button-agree-terms" },
          [
            _c(
              VBtn,
              {
                staticClass: "secondary",
                on: {
                  click: function ($event) {
                    return _vm.$emit("back")
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("go_back")))])]
            ),
            _c(
              VBtn,
              {
                staticClass: "primary",
                attrs: { loading: _vm.loading },
                on: { click: _vm.renewContracts },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("agree_terms_renew")))])]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }